/*define styles here*/
@import "node_modules/bootstrap/scss/functions";
$body-bg: white;
$body-color: #444;
$close-color: $body-color;
$super-light: rgba(white, 0.02);
$orange: #f8ae0a;
$secondary: #6c757d;
$panel: rgba(0, 0, 0, 0.3);
$light: #e4e4e4;
$theme-colors: (
  "primary": #c30a14,
  "orange": $orange,
  "light": $light,
  "seconday": $secondary,
  "super-light": $super-light,
  "panel": $panel,
);
// $input-bg: $body-bg;
// $input-color: #fffeff;
$container-max-widths: (
  sm: 540px,
  md: 820px,
  lg: 960px,
  xl: 1680px,
) !default;

$grid-gutter-width: 20px !default;

// $modal-content-bg: #0b1228;
// $modal-backdrop-bg: #fff;
// $modal-backdrop-opacity: .2;
// $modal-header-border-color: rgba(white, 0.1);
// $modal-footer-border-color: rgba(white, 0.1);
// $border-color:rgba(white, 0.1);
// bootstrap
// $link-color: rgba(#fffeff, 0.8);
// $link-hover-color: #fffeff;
$font-family-sans-serif: "Poppins", sans-serif;
$enable-rounded: false;
$enable-responsive-font-sizes: true;

@import "./bootstrap-variables";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// custom
@import "node_modules/bootstrap/scss/bootstrap";

.breadcrumb {
  background-color: $secondary;
}

.header-progress {
  &__link {
    text-decoration: none !important;
  }
}
.wrapper {
  min-height: 100vh;
  padding-bottom: 120px;
  position: relative;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  img {
    width: 30px;
  }
}
.signal-type {
  width: 120px;
  color: white;
  &.-data {
    background: #0f44c4;
  }
  &.-reserve {
    background: #a01c82;
  }
}

.signal-status {
  width: 155px;
  color: white;
  background: #34495e;
  &.-closed-accepted {
    background-color: #28a745;
  }
  &.-closed-declined {
    background-color: #dc3545;
  }
  &.-open-accept {
    background: rgba(#28a745, 0.6);
    cursor: pointer;
    &:hover {
      background: #28a745;
    }
  }
  &.-open-decline {
    background: rgba(#dc3545, 0.6);
    cursor: pointer;
    &:hover {
      background: #dc3545;
    }
  }
  &.-open-review {
    background: rgba(#ffc107, 0.6);
    cursor: pointer;
    &:hover {
      background: #ffc107;
    }
  }
}

.signal-date {
  background: $light;
  color: $body-color;
}

.btn-radio {
  &.btn-outline-success,
  &.btn-outline-danger {
    color: $body-color;
    border-color: $secondary;
  }
  &.btn-outline-danger:hover {
    border-color: $danger;
  }
  &.btn-outline-success:hover {
    border-color: $success;
  }
}

.signals {
  &__area {
    height: 70vh;
    margin-left: -15px;
    margin-right: -15px;
    .scrollarea-content {
      padding: 0 15px;
    }
    .scrollbar-container.vertical .scrollbar {
      border-radius: 8px;
      background: #c30a14 !important;
    }
  }
  &__item {
    &:nth-of-type(odd) {
      background-color: rgba($light, 0.3);
    }
    &:hover {
      background-color: $light;
    }
    &.-active {
      background-color: $light;
    }
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.react-html5-camera-photo {
  > img,
  > video {
    width: 100% !important;
  }
}

.ocr-camera {
  &__toggle-aligner {
    position: relative;
    z-index: 999;
    width: 100%;
  }

  &__processed-image{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 33%;
    img{
      width: 100%;
    }
  }

  &__toggle {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__overlay{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
  }


  width: 100%;

  .camera {
    position: relative;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    video {
      width: 100%;
    }
  }

  &.-item-9-1138-0467-0.-Front .ocr-camera__overlay {
    background-size: auto 100%;
    background-image: url(../img/outlines/item-9-1138-0467-0-Front.png);
  }
  &.-item-9-1138-0821-4.-Side .ocr-camera__overlay {
    // background-image: url(../img/outlines/item-9-1138-0821-4-Side.png);
  }
  &.-item-9-1138-0821-4.-Side .ocr-camera__overlay {
    // background-image: url(../img/outlines/item-9-1138-0821-4-Side.png);
  }
  &.-item-9-1138-0467-0.-Back .ocr-camera__overlay {
    background-size: auto 100%;
    background-image: url(../img/outlines/item-9-1138-0467-0-Back.png);
  }
  &.-item-9-1185-0454-2.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0454-2-Tag.png);
  }
  &.-item-9-1185-0465-0.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0465-0-Tag.png);
  }
  &.-item-9-1189-0295-0.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1189-0295-0-Tag.png);
  }
  &.-item-9-1185-0467-0.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0467-0-Tag.png);
  }
  &.-item-9-1185-0453-0.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0453-0-Tag.png);
  }
  &.-item-9-1185-0453-1.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0453-1-Tag.png);
  }
}

.btn {
  position: relative;
  .spinner {
    position: absolute;
    left: 10px;
    transform: translateY(-50%);
    top: 41%;
  }
}

.border-dotted {
  border-style: dotted;
}

.border-dashed {
  border-style: dashed !important;
}

/*define styles here*/
@import "node_modules/bootstrap/scss/functions";
$body-bg: white;
$body-color: #444;
$close-color: $body-color;
$super-light: rgba(white, 0.02);
$orange: #f8ae0a;
$secondary: #6c757d;
$panel: rgba(0, 0, 0, 0.3);
$light: #e4e4e4;
$theme-colors: (
  "primary": #c30a14,
  "orange": $orange,
  "light": $light,
  "seconday": $secondary,
  "super-light": $super-light,
  "panel": $panel,
);
// $input-bg: $body-bg;
// $input-color: #fffeff;
$container-max-widths: (
  sm: 540px,
  md: 820px,
  lg: 960px,
  xl: 1680px,
) !default;

$grid-gutter-width: 20px !default;

// $modal-content-bg: #0b1228;
// $modal-backdrop-bg: #fff;
// $modal-backdrop-opacity: .2;
// $modal-header-border-color: rgba(white, 0.1);
// $modal-footer-border-color: rgba(white, 0.1);
// $border-color:rgba(white, 0.1);
// bootstrap
// $link-color: rgba(#fffeff, 0.8);
// $link-hover-color: #fffeff;
$font-family-sans-serif: "Poppins", sans-serif;
$enable-rounded: false;
$enable-responsive-font-sizes: true;

@import "./bootstrap-variables";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// custom
@import "node_modules/bootstrap/scss/bootstrap";

.breadcrumb {
  background-color: $secondary;
}

.header-progress {
  &__link {
    text-decoration: none !important;
  }
}
.wrapper {
  min-height: 100vh;
  padding-bottom: 120px;
  position: relative;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  img {
    width: 30px;
  }
}
.signal-type {
  width: 120px;
  color: white;
  &.-data {
    background: #0f44c4;
  }
  &.-reserve {
    background: #a01c82;
  }
}

.signal-status {
  width: 155px;
  color: white;
  background: #34495e;
  &.-closed-accepted {
    background-color: #28a745;
  }
  &.-closed-declined {
    background-color: #dc3545;
  }
  &.-open-accept {
    background: rgba(#28a745, 0.6);
    cursor: pointer;
    &:hover {
      background: #28a745;
    }
  }
  &.-open-decline {
    background: rgba(#dc3545, 0.6);
    cursor: pointer;
    &:hover {
      background: #dc3545;
    }
  }
  &.-open-review {
    background: rgba(#ffc107, 0.6);
    cursor: pointer;
    &:hover {
      background: #ffc107;
    }
  }
}

.signal-date {
  background: $light;
  color: $body-color;
}

.btn-radio {
  &.btn-outline-success,
  &.btn-outline-danger {
    color: $body-color;
    border-color: $secondary;
  }
  &.btn-outline-danger:hover {
    border-color: $danger;
  }
  &.btn-outline-success:hover {
    border-color: $success;
  }
}

.signals {
  &__area {
    height: 70vh;
    margin-left: -15px;
    margin-right: -15px;
    .scrollarea-content {
      padding: 0 15px;
    }
    .scrollbar-container.vertical .scrollbar {
      border-radius: 8px;
      background: #c30a14 !important;
    }
  }
  &__item {
    &:nth-of-type(odd) {
      background-color: rgba($light, 0.3);
    }
    &:hover {
      background-color: $light;
    }
    &.-active {
      background-color: $light;
    }
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-100 {
  opacity: 1;
}

.react-html5-camera-photo {
  > img,
  > video {
    width: 100% !important;
  }
}

.ocr-camera {
  &__toggle-aligner {
    position: relative;
    z-index: 999;
    width: 100%;
  }

  &__toggle {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__overlay{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
  }


  width: 100%;

  .camera {
    position: relative;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    video {
      width: 100%;
    }
  }

  &.-item-9-1138-0467-0.-Front .ocr-camera__overlay {
    background-size: auto 100%;
    background-image: url(../img/outlines/item-9-1138-0467-0-Front.png);
  }
  &.-item-9-1138-0821-4.-Side .ocr-camera__overlay {
    // background-image: url(../img/outlines/item-9-1138-0821-4-Side.png);
  }
  &.-item-9-1138-0821-4.-Side .ocr-camera__overlay {
    // background-image: url(../img/outlines/item-9-1138-0821-4-Side.png);
  }
  &.-item-9-1138-0467-0.-Back .ocr-camera__overlay {
    background-size: auto 100%;
    background-image: url(../img/outlines/item-9-1138-0467-0-Back.png);
  }
  &.-item-9-1185-0454-2.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0454-2-Tag.png);
  }
  &.-item-9-1185-0465-0.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0465-0-Tag.png);
  }
  &.-item-9-1189-0295-0.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1189-0295-0-Tag.png);
  }
  &.-item-9-1185-0467-0.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0467-0-Tag.png);
  }
  &.-item-9-1185-0453-0.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0453-0-Tag.png);
  }
  &.-item-9-1185-0453-1.-Tag .ocr-camera__overlay {
    background-image: url(../img/outlines/item-9-1185-0453-1-Tag.png);
  }
}

.btn {
  position: relative;
  .spinner {
    position: absolute;
    left: 10px;
    transform: translateY(-50%);
    top: 41%;
  }
}

.border-dotted {
  border-style: dotted;
}

.border-dashed {
  border-style: dashed !important;
}
